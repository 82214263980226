
const serverCalls = {

    // apiUrl: 'http://nocode.api.dosystemsinc.com/api/',
    // imgUrl: 'http://api.nocode.dosystemsinc.com/api/',

    // apiUrl: "https://nocodedevapi.aicodeless.com/api/",
    // imgUrl: "https://nocodedevapi.aicodeless.com/images/",
    // logoUrl: "https://nocodedevapi.aicodeless.com/image/",
    // socketUrl: "https://nocodedevapi.aicodeless.com",

    apiUrl: "https://nocodetestapi.aicodeless.com/api/",
    imgUrl: "https://nocodetestapi.aicodeless.com/images/",
    logoUrl: "https://nocodetestapi.aicodeless.com/image/",
    socketUrl: "https://nocodetestapi.aicodeless.com",

    // apiUrl: "http://localhost:7256/api/",
    // imgUrl: "http://localhost:7256/images/",
    // logoUrl: "http://localhost:7256/image/",
    // socketUrl: "http://localhost:7256",
    serverErrMessage: 'Could Not reach server',
    entityType: "user",

    logoutTime: 86400000,

    //regex
    borderValidation: false,
    messages: true,
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/,
    aadharcardNumberRegex: /^([0-9]){12}$/,
    pancardNumberRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
    phoneNumberRegex: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,

    // server response codes
    updateResCode: 205,
    deleteResCode: 206,

    datePlaceholder: '--/--/----',
    dateFormat: 'MM/DD/YYYY',
    dateDashFormat: 'YYYY-MM-DD',
    basicDateFromat: 'MM/DD/YYYY HH:mm A',
    descDateFromat: 'MMM DD YYYY HH:mm A',
    dateDmyFormat: 'DD-MM-YYYY',
    timeFormat: 'HH:mm',
    syncTimeFormat: 'hh:mm A, MM-DD-YYYY',
    lastModifiedDateFormat: 'MM/DD/YYYY HH:mm',
    dateTimeFormat: 'MM/DD/YYYY hh:mm',
    fullDateFormat: 'YYYY-MM-DD HH:mm:ss',
    dbDateFormat: 'YYYY-MM-DD[T]HH:mm:ss.SSS',
    dbOnlyDateFormat: 'YYYY-MM-DD[T]00:00:00',
    ESTTimezone: "America/New_York",

    noView: 'noView',
    edit: 'edit',
    view: 'view',

    //colors
    floatingFieldsColor: '#1976d2',
    helperTextColor: 'rgba(0, 0, 0, 0.54)',
};
export default serverCalls;
